<template>
  <div v-if="brand">
    <button
      class="sidebar__trigger"
      :aria-label="getDictionaryEntry('Common.Menu')"
      @click="open"
    >
      <svg
        class="sidebar__trigger-icon"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs />
        <path d="M3 12h18M3 6h18M3 18h18" />
      </svg>
    </button>
    <div class="sidebar" :class="{ 'sidebar--visible': visible }">
      <div class="sidebar__block">
        <nav>
          <router-link :to="{ name: startPageForUser }" class="sidebar__logo">
            <img
              :src="brand.logo"
              :alt="`${brand.name} logo`"
              class="sidebar__logo-image"
            />
          </router-link>
          <ol class="sidebar__list">
            <li>
              <router-link :to="{ name: 'customers' }" class="sidebar__link"
                ><span class="sidebar__link-text">{{
                  getDictionaryEntry("Navigation.Customers")
                }}</span></router-link
              >
              <div v-if="isCustomerActive">
                <div class="sidebar__list-title">
                  {{ nameOfCurrentCustomer }}
                </div>
                <ol class="sidebar__list sidebar__list--sub">
                  <li v-if="hasAccessToCustomer">
                    <router-link
                      :to="{ name: 'customer.details' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.Details")
                      }}</span></router-link
                    >
                  </li>
                  <li v-if="hasAccessToCustomerUsers">
                    <router-link
                      :to="{ name: 'customer.users' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.Users")
                      }}</span></router-link
                    >
                  </li>
                  <li v-if="hasAccessToCustomerRoles">
                    <router-link
                      :to="{ name: 'customer.roles' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.Roles")
                      }}</span></router-link
                    >
                  </li>
                  <li v-if="hasAccessToCustomerGroups">
                    <router-link
                      :to="{ name: 'customer.userGroups' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.UserGroups")
                      }}</span></router-link
                    >
                  </li>
                  <li v-if="hasAccessToCustomerPrices">
                    <router-link
                      :to="{ name: 'customer.products' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.Prices")
                      }}</span></router-link
                    >
                  </li>
                  <li v-if="hasAccessToCustomerWardrobes">
                    <router-link
                      :to="{ name: 'customer.wardrobes' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.Wardrobes")
                      }}</span></router-link
                    >
                  </li>
                  <li v-if="hasAccessToCustomerAddresses">
                    <router-link
                      :to="{ name: 'customer.addresses' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.DeliveryAddresses")
                      }}</span></router-link
                    >
                  </li>
                  <li v-if="hasAccessToCustomerOrders">
                    <router-link
                      :to="{ name: 'customer.orders' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.CustomerOrders")
                      }}</span></router-link
                    >
                  </li>
                  <li v-if="hasAccessToSalespersons">
                    <router-link
                      :to="{ name: 'customer.salespersons' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.Salespersons")
                      }}</span></router-link
                    >
                  </li>
                  <li
                    v-if="hasAccessToEndCustomers && isDealerWithEndCustomers"
                  >
                    <router-link
                      :to="{ name: 'customer.endCustomers' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.EndCustomers")
                      }}</span></router-link
                    >
                  </li>
                  <li
                    v-if="
                      hasAccessToExternalProducts && isDealerWithEndCustomers
                    "
                  >
                    <router-link
                      :to="{ name: 'customer.externalProducts' }"
                      class="sidebar__link"
                      ><span class="sidebar__link-text">{{
                        getDictionaryEntry("Navigation.ExternalProducts")
                      }}</span></router-link
                    >
                  </li>
                </ol>
              </div>
            </li>
            <li v-if="hasAccessToOrderManagement">
              <router-link :to="{ name: 'orders' }" class="sidebar__link"
                ><span class="sidebar__link-text">{{
                  getDictionaryEntry("Navigation.AllOrders")
                }}</span></router-link
              >
            </li>
            <li v-if="hasAccessToRoleTemplates">
              <router-link :to="{ name: 'roleTemplates' }" class="sidebar__link"
                ><span class="sidebar__link-text">{{
                  getDictionaryEntry("Navigation.RoleTemplates")
                }}</span></router-link
              >
            </li>
            <li v-if="hasAccessToSalespersons">
              <router-link :to="{ name: 'salespersons' }" class="sidebar__link"
                ><span class="sidebar__link-text">{{
                  getDictionaryEntry("Navigation.Salespersons")
                }}</span></router-link
              >
            </li>
            <li v-if="hasAccessToCustomerService">
              <router-link
                :to="{ name: 'customerServiceRepresentatives' }"
                class="sidebar__link"
                ><span class="sidebar__link-text">{{
                  getDictionaryEntry("Navigation.CustomerService")
                }}</span></router-link
              >
            </li>
            <li v-if="hasAccessToSystemadministrators">
              <router-link
                :to="{ name: 'systemadministrators' }"
                class="sidebar__link"
                ><span class="sidebar__link-text">{{
                  getDictionaryEntry("Navigation.Systemadministrators")
                }}</span></router-link
              >
            </li>
          </ol>
        </nav>
      </div>

      <ol class="sidebar__list sidebar__list--bottom sidebar__block">
        <li v-if="hasAccessToProductSheet && !onBehalfUserName">
          <div
            v-if="
              allowedDivisionsForCurrentUser &&
                allowedDivisionsForCurrentUser.length > 1
            "
          >
            <button
              class="sidebar__link sidebar__link--button"
              :class="{ 'sidebar__link--open': isCatalogueDivisionsOpen }"
              @click="toggleCatalogueDivisions"
            >
              {{ getDictionaryEntry("Navigation.Catalogue") }}
            </button>
            <div ref="catalogueDivisions" class="catalogue-divisions">
              <div class="catalogue-divisions__label">
                {{ getDictionaryEntry("Common.Placeholders.SelectDivision") }}
              </div>
              <ol class="catalogue-divisions__list">
                <li
                  v-for="brand in allowedDivisionBrands"
                  :key="brand.id"
                  :class="{
                    'catalogue-divisions__list--one-third':
                      allowedDivisionBrands.length &&
                      allowedDivisionBrands.length === 3,
                  }"
                  class="catalogue-divisions__item"
                >
                  <button
                    class="catalogue-divisions__link"
                    @click="goToDivision(brand)"
                  >
                    <img
                      :src="brand.logo"
                      :alt="`${brand.name} logo`"
                      class="catalogue-divisions__image"
                      decoding="async"
                      loading="lazy"
                      @error="handleDivisionLogoError($event, brand)"
                    />
                  </button>
                </li>
              </ol>
            </div>
          </div>
          <router-link v-else :to="{ name: 'catalogue' }" class="sidebar__link"
            ><span class="sidebar__link-text">
              {{ getDictionaryEntry("Navigation.Catalogue") }}
            </span></router-link
          >
        </li>
        <li v-if="hasAccessToShop">
          <router-link :to="shopRedirect" class="sidebar__link"
            ><span class="sidebar__link-text">
              {{ getDictionaryEntry("Navigation.Shop") }}
            </span></router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'administrationProfile' }"
            class="sidebar__link"
            ><span class="sidebar__link-text">
              {{ getDictionaryEntry("Navigation.Profile") }}
            </span></router-link
          >
        </li>
        <li>
          <button class="sidebar__link sidebar__link--button" @click="LOGOUT">
            <span class="sidebar__link-text">{{
              getDictionaryEntry("Common.Logout")
            }}</span>
          </button>
        </li>
      </ol>
    </div>
    <transition name="fade">
      <div v-if="visible" class="sidebar__underlay" @click="close"></div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { slideDown, slideUp } from "@/helpers/slide";

export default {
  name: "NavigationSidebar",
  data() {
    return {
      isCustomerActive: false,
      isCatalogueDivisionsOpen: false,
      visible: false,
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "customerName",
      "onBehalfUserName",
      "userConfiguration",
    ]),
    ...mapGetters("branding", ["brand", "brands"]),
    ...mapGetters("customers", ["nameOfCurrentCustomer", "currentCustomer"]),
    ...mapGetters("users", ["allowedDivisionsForCurrentUser"]),
    ...mapGetters("content", ["navigation"]),
    allowedDivisionBrands() {
      if (!this.allowedDivisionsForCurrentUser || !this.brands) return [];

      const allowedDivisonsInLowerCase = this.allowedDivisionsForCurrentUser
        .filter(division => division)
        .map(division => division.toLowerCase());

      if (!allowedDivisonsInLowerCase || !allowedDivisonsInLowerCase.length) {
        return [];
      }

      return this.brands
        .filter(brand =>
          allowedDivisonsInLowerCase.includes(brand.divisionId.toLowerCase()),
        )
        .sort((a, b) => a.divisionId.localeCompare(b.divisionId));
    },
    //customerId() {
    //    return this.$route.params?.customerId;
    //},
    isDealerWithEndCustomers() {
      return (
        this.currentCustomer &&
        this.currentCustomer.hasChildren &&
        this.currentCustomer.customerType.toLowerCase() === "dealer"
      );
    },
    startPageForUser() {
      return this.getStartPageForCurrentUser();
    },
    shopRedirect() {
      if (!this.navigation?.items) return { name: "products" };

      const url = this.navigation?.items?.find(item =>
        item.url.includes("/shop"),
      )?.url;

      return url ? url : { name: "products" };
    },
  },
  watch: {
    "$route.name": function(newValue) {
      if (this.visible) this.close();

      const isCustomerActiveAfterChange = newValue
        .toLowerCase()
        .startsWith("customer.");

      if (isCustomerActiveAfterChange === this.isCustomerActive) return;

      this.isCustomerActive = isCustomerActiveAfterChange;
    },
  },
  created() {
    this.isCustomerActive = this.$route.name
      .toLowerCase()
      .startsWith("customer.");
  },
  methods: {
    ...mapActions("authentication", ["LOGOUT"]),
    ...mapActions("catalogues", ["REMOVE_ALL_VARIANTS_FROM_CURRENT_CATALOGUE"]),
    close() {
      this.visible = false;
    },
    getCatalogueUrlForBrand(brand) {
      if (!brand || !brand.canonicalWebsiteUrl) return null;

      return `${window.location.protocol}//${brand.canonicalWebsiteUrl}/#/catalogue`;
    },
    goToDivision(brand) {
      if (!brand) return;

      const url = this.getCatalogueUrlForBrand(brand);

      if (!url) return;

      this.REMOVE_ALL_VARIANTS_FROM_CURRENT_CATALOGUE().then(() => {
        window.location.href = url;
      });
    },
    open() {
      this.visible = true;
    },
    toggleCatalogueDivisions() {
      const contentElement = this.$refs.catalogueDivisions;

      if (!this.isCatalogueDivisionsOpen) {
        slideDown(contentElement);
      } else {
        slideUp(contentElement);
      }

      this.isCatalogueDivisionsOpen = !this.isCatalogueDivisionsOpen;
    },
    handleDivisionLogoError(e, brand) {
      const { name } = brand;
      const parentNode = e.target.parentNode;
      parentNode.style.backgroundColor = "#eee";
      e.target.style.display = "none";

      const span = document.createElement("span");
      span.innerHTML = name;
      parentNode.prepend(span);
    },
  },
};
</script>

<style lang="scss" scoped>
.catalogue-divisions {
  display: none;
  overflow: hidden;

  &__image {
    width: 100%;
    height: 100%;
  }

  &__link {
    filter: grayscale(1);
    opacity: 0.5;
    transition: filter 300ms ease, opacity 300ms ease;
    width: 100%;

    &:hover {
      filter: grayscale(0);
      opacity: 1;
    }
  }

  &__item {
    flex: 1 1 50%;
    margin-bottom: var(--spacing-stack);
    overflow: hidden;

    .catalogue-divisions__list--one-third & {
      flex-basis: (100%/3);
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__label {
    font-size: 0.9375rem;
    margin: 0.75rem 0 1rem;
    padding: 0 var(--spacing-stack);
  }

  &__link {
    @include reset-button();

    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
    min-width: 50px;
    min-height: 28px;
  }

  &__list {
    @include reset-list();

    display: flex;
    padding: 0 var(--spacing-stack);
  }
}

.sidebar {
  background-color: var(--color-navigation-sidebar-background);
  box-shadow: var(--elevation-16);
  bottom: 0;
  color: var(--color-navigation-sidebar-text);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 0;
  transition: transform 300ms ease;
  transform: translateX(-100%) translateX(-1rem);
  width: var(--width-navigation-sidebar);
  z-index: 97;

  @media (min-width: 64rem) {
    transform: none;
  }

  &--visible {
    transform: translateX(0);
  }

  &__block {
    flex: 1 1 auto;
    overflow-y: auto;

    &:last-child {
      flex: 0 0 auto;
    }
  }

  &__list {
    @include reset-list();

    &:first-child {
      margin-top: calc(var(--spacing-stack) * 0.5);
    }

    &:last-child {
      margin-bottom: calc(var(--spacing-stack) * 0.5);
    }

    &--bottom {
      border-top: 1px solid var(--color-navigation-sidebar-border);
      padding-top: calc(var(--spacing-stack) * 0.25);
      padding-bottom: 1.3rem;
    }

    &--sub {
      border-bottom: 1px solid var(--color-navigation-sidebar-border);
      margin: 0 !important;
    }

    &-title {
      font-size: 0.9375rem;
      font-weight: 700;
      padding: calc(var(--spacing-stack) * 0.5) var(--spacing-stack);
      text-transform: uppercase;
    }
  }

  &__link {
    color: inherit;
    display: block;
    font-size: 1rem;
    padding: calc(var(--spacing-stack) * 0.5) var(--spacing-stack);
    text-decoration: none;
    width: 100%;

    .sidebar__list--sub & {
      background-color: transparent;
      padding-left: calc(var(--spacing-stack) * 1.5);
      transition: background-color 300ms ease;

      &.router-link-active {
        background-color: var(--color-navigation-sidebar-link-background);
      }
    }

    &--button {
      @include reset-button();

      padding: calc(var(--spacing-stack) * 0.5) var(--spacing-stack);
      text-align: left;
    }

    &--open {
      font-weight: 700;
    }

    &-text {
      display: inline-block;
      position: relative;

      .sidebar__list--sub & {
        &::after {
          display: none;
        }
      }

      &::after {
        background-color: var(--color-navigation-sidebar-text);
        bottom: -4px;
        content: "";
        display: block;
        left: 0;
        height: 2px;
        position: absolute;
        right: 0;
        transform: scale3d(0, 1, 1);
        transform-origin: center;
        transition: transform 300ms ease;
      }

      .router-link-active & {
        &::after {
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }

  &__logo {
    display: block;
    margin: var(--spacing-stack) var(--spacing-stack)
      calc(var(--spacing-stack) * 2);
  }

  &__trigger {
    @include reset-button;

    display: block;
    padding: var(--spacing-header-actions);
    position: relative;
    text-align: center;
    text-decoration: none;

    @media (min-width: 64rem) {
      display: none;
    }

    &-icon {
      fill: none;
      height: var(--size-header-action-icon);
      opacity: 0.85;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      width: var(--size-header-action-icon);

      * {
        stroke: var(--color-header-text);
      }
    }
  }

  &__underlay {
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 95;

    @media (min-width: 64rem) {
      display: none;
    }
  }
}
</style>
