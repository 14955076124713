var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"quick-buy-product-details"},[_c('SlideIn',{attrs:{"visible":_vm.endCustomerQuickBuyOpen,"has-bottom-button":""},on:{"close":_vm.TOGGLE_END_CUSTOMER_QUICK_BUY},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('DynamicData',{attrs:{"loading":_vm.isLoading,"transition":"fade"}},[_c('Stack',[(_vm.currentProduct && _vm.currentVariant)?_c('div',{staticClass:"quick-buy-product-details__block"},[_c('div',{staticClass:"quick-buy-product-details__content"},[(_vm.isDiscounted)?_c('Stack',{attrs:{"half":true}},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Product.DiscountLabel"))+" ")]):_vm._e(),_c('Stack',[_c('InformationList',{attrs:{"information":{
                    ..._vm.currentProduct,
                    id: _vm.currentVariant.id,
                  }}})],1),(_vm.currentProduct.name)?_c('Stack',{attrs:{"half":true}},[_c('h2',{staticClass:"quick-buy-product-details__title"},[_vm._v(" "+_vm._s(_vm.currentProduct.name)+" ")])]):_vm._e(),_c('Stack',{staticClass:"quick-buy-product-details__price",attrs:{"half":true}},[_c('Price',{attrs:{"price":_vm.groupedPrice}})],1),(
                  _vm.currentProduct.serviceItems &&
                    _vm.currentProduct.serviceItems.length
                )?_c('Stack',[_c('ol',{staticClass:"quick-buy-product-details__service-items"},_vm._l((_vm.currentProduct.serviceItems),function(serviceItem){return _c('li',{key:serviceItem.id,staticClass:"quick-buy-product-details__service-item"},[_vm._v(" + "+_vm._s(serviceItem.productNumber)+" "+_vm._s(serviceItem.name)+" ")])}),0)]):_vm._e(),(_vm.currentVariant.swatch && _vm.currentVariant.swatch.alt)?_c('Stack',[_c('h3',{staticClass:"quick-buy-product-details__label"},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Common.Labels.SelectColor"))+" "),_c('span',{staticClass:"quick-buy-product-details__label quick-buy-product-details__label--slim"},[_vm._v("- "+_vm._s(_vm.currentVariant.swatch.alt))])]),_c('Swatches',{attrs:{"product":_vm.currentProduct,"end-customer-quick-buy-mode":""}})],1):_vm._e(),(
                  _vm.currentVariant.purchaseLimitationDescriptions &&
                    _vm.currentVariant.purchaseLimitationDescriptions.length
                )?_c('Stack',{attrs:{"one-and-half":true}},[_c('h3',{staticClass:"quick-buy-product-details__label"},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Administration.PurchaseLimitations"))+" ")])]):_vm._e(),(_vm.isShopToState)?_c('Stack',[_c('ShopToSizeSelectorQuickBuy',{attrs:{"sizes":_vm.ungroupedSizes,"add-to-basket-clicked":_vm.addToBasketClicked},on:{"set-footer-text-label":_vm.handleFooterText,"set-footer-button-disabled":_vm.handleFooterDisabled,"set-footer-button-loading":_vm.handleFooterButtonLoading}})],1):_c('Stack',{attrs:{"one-and-half":true}},[_c('SizeSelectorQuickBuy',{attrs:{"has-size-groups":_vm.hasSizeGroups,"sizes":_vm.sizes,"add-to-basket-clicked":_vm.addToBasketClicked},on:{"set-footer-text-label":_vm.handleFooterText,"set-footer-button-disabled":_vm.handleFooterDisabled,"set-footer-button-loading":_vm.handleFooterButtonLoading,"set-grouped-price":function($event){_vm.groupedPrice = $event}}})],1)],1)]):_vm._e()])],1)]},proxy:true},(!_vm.isLoading)?{key:"footer",fn:function(){return [_c('Button',{attrs:{"buy":true,"disabled":_vm.shouldDisableFooterButton,"round":true,"show-loader":_vm.isLoadingFooterButton},on:{"click":_vm.addToBasket}},[_vm._v(" "+_vm._s(_vm.footerButtonLabel || _vm.getDictionaryEntry("Product.AddToBasket"))+" ")])]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }