<template>
  <div class="size-selector">
    <h3 class="size-selector--title">
      {{ getDictionaryEntry("Common.Labels.SelectSize") }}
    </h3>

    <div class="size-selector__box">
      <div class="size-selector__box-content">
        <Stack v-if="hasSizeGroups && isUserEndCustomer" :double="true">
          <div v-for="(sizeGroup, index) in sizes" :key="sizeGroup.title">
            <div
              class="size-selector__size-group-header"
              :class="{
                'size-selector__size-group-header--not-first': index !== 0,
              }"
            >
              <span>
                {{ getDictionaryEntry("SizeGroup.B2B." + sizeGroup.title) }}
              </span>
              <span class="size-selector__size-span">
                {{ getSizeSpan(sizeGroup) }}
              </span>
            </div>

            <SizeSelectorList
              :key="'groupSize_' + index"
              :sizes="sizeGroup.sizes"
              :selected-sizes="selectedSizes"
              end-customer-quick-buy-mode
              show-counter
              @select-size="selectSize"
              @update-counter-quantity="handleQuantityChange($event)"
            />
          </div>
        </Stack>
        <Stack v-else :double="true">
          <SizeSelectorList
            :sizes="sizes"
            :selected-sizes="selectedSizes"
            end-customer-quick-buy-mode
            show-counter
            @select-size="selectSize"
            @update-counter-quantity="handleQuantityChange($event)"
          />
        </Stack>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SizeSelectorList from "@/components/SizeSelectorList";
import Stack from "@/components/Stack";
import { stockStatuses } from "@/helpers/stockStatuses";
import { alertTypes } from "@/helpers/alertTypes";
import { debounce } from "debounce";

export default {
  name: "SizeSelectorQuickBuy",
  components: {
    SizeSelectorList,
    Stack,
  },
  props: {
    hasSizeGroups: {
      type: Boolean,
      default: false,
    },
    sizes: {
      required: true,
      type: Array,
    },
    addToBasketClicked: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedSizes: {}, // {[sku]: quantity}
      waitingTime: 0,
    };
  },
  computed: {
    ...mapGetters("basket", ["basketLineQuantity"]),
    ...mapGetters("authentication", ["isUserEndCustomer"]),

    shouldDisableButton() {
      return (
        Object.entries(this.selectedSizes)
          // eslint-disable-next-line no-unused-vars
          .filter(([_, quantity]) => quantity > 0)
          // eslint-disable-next-line no-unused-vars
          .map(([sku, _]) => {
            const selectedSize = this.getSize(sku);

            if (!selectedSize?.inventory) return true;

            const selectedQuantityInBasket = this.quantityInBasket(
              selectedSize,
            );

            const {
              inventory: { allowNegativeStock, stock, stockStatus },
            } = selectedSize;

            if (typeof stock === "number") {
              if (allowNegativeStock) {
                return false;
              } else {
                return stock === 0 || selectedQuantityInBasket >= stock;
              }
            } else {
              return stockStatus === stockStatuses.OUT_OF_STOCK;
            }
          })
          .every(a => !!a)
      );
    },
  },
  watch: {
    isLoading(newValue) {
      this.$emit("set-footer-button-loading", newValue);
    },
    shouldDisableButton(newValue) {
      this.$emit("set-footer-button-disabled", newValue);
    },
    addToBasketClicked() {
      this.addToBasket();
    },
    selectedSizes: {
      handler(value) {
        if (this.waitingTime) {
          this.waitingTime.clear();
        }

        this.waitingTime = debounce(() => {
          const selectedSizes = Object.entries(value).filter(
            ([, quantity]) => quantity > 0,
          );

          if (!selectedSizes.length) {
            this.$emit("set-grouped-price", null);
            return;
          }

          const totalPrices = selectedSizes.reduce(
            (acc, [sku, quantity]) => {
              if (quantity > 0) {
                const size = this.getSize(sku);
                if (size?.price) {
                  const {
                    priceValue,
                    normalPriceValue,
                    retailPriceValue,
                    currency,
                  } = size.price;

                  acc.priceValue += priceValue * quantity;
                  acc.normalPriceValue += normalPriceValue * quantity;
                  acc.retailPriceValue += retailPriceValue * quantity;

                  acc.currency = acc.currency || currency;
                }
              }
              return acc;
            },
            {
              priceValue: 0,
              normalPriceValue: 0,
              retailPriceValue: 0,
              currency: "",
            },
          );

          // Format prices
          const formattedPrice = {
            price: this.formatPriceCurrency(
              totalPrices.priceValue,
              totalPrices.currency,
            ),
            normalPrice: this.formatPriceCurrency(
              totalPrices.normalPriceValue,
              totalPrices.currency,
            ),
            retailPrice: this.formatPriceCurrency(
              totalPrices.retailPriceValue,
              totalPrices.currency,
            ),
            ...totalPrices,
          };

          this.$emit("set-grouped-price", formattedPrice);
        }, 300);

        this.waitingTime();
      },
      deep: true,
    },
  },
  created() {
    this.$emit(
      "set-footer-text-label",
      this.getDictionaryEntry("Product.AddToBasket"),
    );
  },
  methods: {
    ...mapActions("basket", ["UPDATE_BASKET_LINE_QUANTITY"]),
    ...mapActions("products", ["TOGGLE_END_CUSTOMER_QUICK_BUY"]),
    ...mapActions("alerts", ["OPEN_ALERT"]),
    quantityInBasket(size) {
      return this.basketLineQuantity(size?.sku);
    },
    async addToBasket() {
      this.isLoading = true;

      try {
        for (const [sku, quantity] of Object.entries(this.selectedSizes)) {
          if (quantity > 0) {
            const size = this.getSize(sku);

            if (size) {
              await this.UPDATE_BASKET_LINE_QUANTITY({
                previousQuantity: this.quantityInBasket(size),
                quantity: quantity + this.quantityInBasket(size),
                sku: size.sku,
                hideAlert: true,
              });
            }
          }
        }
        this.isLoading = false;
        await this.OPEN_ALERT({
          key: "SuccessMessage.BasketUpdated",
          type: alertTypes.SUCCESS,
        });
        this.TOGGLE_END_CUSTOMER_QUICK_BUY();
      } catch (error) {
        this.isLoading = false;
      }
    },
    getSizeSpan(sizeGroup) {
      const first = this.getPreferredSizeChartValue(sizeGroup.sizes[0]);
      const last = this.getPreferredSizeChartValue(
        sizeGroup.sizes[sizeGroup.sizes.length - 1],
      );

      return `${first} - ${last}`;
    },
    selectSize(size) {
      const sku = size.sku;
      const copy = { ...(this.selectedSizes ?? {}) };

      const notExist = copy[sku] === null || copy[sku] === undefined;

      if (notExist) {
        copy[sku] = 0;
      } else if (copy[sku] === 0) {
        delete copy[sku];
        this.selectedSizes = copy;
      }

      // Remove all keys where quantity is 0 and different from the selected SKU
      Object.keys(copy).forEach(key => {
        if (key !== sku && copy[key] === 0) {
          delete copy[key];
        }
      });

      this.selectedSizes = copy;
    },
    handleQuantityChange({ quantity, sku }) {
      const copy = { ...(this.selectedSizes ?? {}) };
      copy[sku] = quantity;
      this.selectedSizes = copy;
    },
    getSize(sku) {
      if (this.hasSizeGroups) {
        return this.sizes
          .map(sizeGroup => sizeGroup.sizes)
          .flat()
          .find(size => size.sku === sku);
      }

      return this.sizes.find(size => size.sku === sku);
    },
  },
};
</script>

<style lang="scss" scoped>
.size-selector {
  border-top: 1px solid #eaebee;
  padding-top: var(--spacing-stack);

  &--title {
    font-size: 0.9375rem;
    margin: 0 0 var(--spacing-stack);
  }

  &__box {
    background-color: var(--color-card-background);
  }

  &__size-group-header {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin-bottom: calc(var(--spacing-stack) * 0.5);

    &--not-first {
      margin: calc(var(--spacing-stack) * 0.5) 0;
    }

    &--title {
      font-size: 0.875rem;
      font-weight: 700;
    }
  }

  &__size-span {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
  }
}
</style>
