<template>
  <transition-group
    class="alerts"
    tag="div"
    name="fade-slide-from-bottom"
    appear
    :class="{
      'alerts-right': showNotificationsOnRight,
    }"
  >
    <div
      v-for="alert in clonedAlerts"
      :key="alert.id"
      class="alert"
      :class="`alert--${alert.type}`"
      role="alert"
      @mouseenter="PAUSE_ALERT({ id: alert.id })"
      @mouseleave="RESUME_ALERT({ id: alert.id })"
    >
      <button
        class="alert__close"
        :aria-label="getDictionaryEntry('Common.Close')"
        @click="CLOSE_ALERT({ id: alert.id })"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 21 21"
          class="alert__close-icon"
        >
          <defs />
          <g>
            <path d="M5.5 15.5l10-10M15.5 15.5l-10-10z" />
          </g>
        </svg>
      </button>
      <div class="alert__content">
        <svg
          v-if="alert.type === alertTypes.ERROR"
          xmlns="http://www.w3.org/2000/svg"
          class="alert__icon"
          viewBox="0 0 24 24"
        >
          <defs />
          <circle cx="12" cy="12" r="10" />
          <path d="M12 8v4M12 16h.01" />
        </svg>
        <svg
          v-else-if="alert.type === alertTypes.SUCCESS"
          xmlns="http://www.w3.org/2000/svg"
          class="alert__icon"
          viewBox="0 0 24 24"
        >
          <defs />
          <path d="M20 6L9 17l-5-5" />
        </svg>
        <p class="alert__text">
          {{
            interpolateString(
              alert.message,
              ["productName", "quantity"],
              alert.data
                ? [alert.data.productName, alert.data.quantity]
                : ["", ""],
            )
          }}
        </p>
      </div>
      <div
        :ref="alert.id"
        :class="{ 'alert__progress--paused': alert.isPaused }"
        class="alert__progress"
      ></div>
    </div>
  </transition-group>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import { mapActions, mapGetters } from "vuex";
import { alertTypes } from "@/helpers/alertTypes";

export default {
  name: "Alerts",
  data() {
    return {
      alertTypes,
      showNotificationsOnRight: false,
    };
  },
  computed: {
    ...mapGetters("alerts", ["alerts"]),
    clonedAlerts() {
      return cloneDeep(this.alerts);
    },
  },
  watch: {
    clonedAlerts(newValue, oldValue) {
      const { $nextTick, $refs, CLOSE_ALERT } = this;

      const uniqueAlerts = newValue.filter(
        newAlert => !oldValue.some(oldAlert => oldAlert.id === newAlert.id),
      );

      uniqueAlerts.map(alert => {
        $nextTick(() => {
          if (!$refs[alert.id]) return;

          $refs[alert.id][0].addEventListener("animationend", () => {
            CLOSE_ALERT({ id: alert.id });
          });
        });
      });
    },
    "$route.path": function(newValue) {
      this.showNotificationsOnRight = newValue
        .toLowerCase()
        .startsWith("/administration/");
    },
  },
  methods: {
    ...mapActions("alerts", ["CLOSE_ALERT", "PAUSE_ALERT", "RESUME_ALERT"]),
  },
};
</script>

<style lang="scss" scoped>
@keyframes progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.alert {
  background-color: var(--color-alert-background);
  border-radius: 0.25rem;
  box-shadow: var(--elevation-64);
  margin: var(--spacing-container);
  max-width: 30rem;
  overflow: hidden;
  padding: calc(var(--size-alert-close-icon) * 1);
  position: relative;

  @media (min-width: 48rem) {
    margin: var(--spacing-stack);
  }

  &--error {
    &::before {
      background: radial-gradient(
        circle,
        var(--color-error) 0%,
        rgba(255, 255, 255, 0) 25%
      );
    }
  }

  &--success {
    &::before {
      background: radial-gradient(
        circle,
        var(--color-success) 0%,
        rgba(255, 255, 255, 0) 25%
      );
    }
  }

  &::before {
    border-radius: 50%;
    content: "";
    display: block;
    height: 100rem;
    opacity: 0.15;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%) translate(2.5rem, -5rem);
    width: 100rem;
    z-index: 1;
  }

  &__close {
    @include reset-button();

    & {
      position: absolute;
      right: calc(var(--size-alert-close-icon) * 0.1);
      top: calc(var(--size-alert-close-icon) * 0.1);
      z-index: 2;
    }

    &,
    &-icon {
      height: var(--size-alert-close-icon);
      width: var(--size-alert-close-icon);
    }

    &-icon {
      fill-rule: evenodd;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
    }
  }

  &__content {
    align-items: center;
    display: flex;
  }

  &__icon {
    fill: none;
    flex: 0 0 auto;
    height: var(--size-alert-icon);
    margin-right: calc(var(--size-alert-icon) * 0.75);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    width: var(--size-alert-icon);

    .alert--error & {
      stroke: var(--color-error);
    }

    .alert--success & {
      stroke: var(--color-success);
    }
  }

  &__progress {
    animation: progress;
    animation-duration: 1500ms;
    animation-timing-function: linear;
    bottom: 0;
    height: 0.25rem;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 3;

    .alert--error & {
      background-color: var(--color-error);
    }

    .alert--success & {
      background-color: var(--color-success);
    }

    &--paused {
      animation-play-state: paused;
    }
  }

  &__text {
    flex: 1 1 auto;
    line-height: 1.5rem;
    margin: 0;
    position: relative;
    z-index: 2;
  }
}

.alerts {
  bottom: 0;
  position: fixed;
  left: 0;
  z-index: 9999;

  &-right {
    right: 0;
    left: auto;
  }
}
</style>
